
































import { Component, Mixins } from 'vue-property-decorator'
import Chat16 from '@carbon/icons-vue/es/chat/16'

import PermissionsMixin from '@/mixins/permissionsMixin'
import ServiceMessages from '@/partials/tables/services/Messages.vue'

@Component({
  components: {
    Chat16,
    ServiceMessages
  }
})
export default class ServiceMessagesIndex extends Mixins(PermissionsMixin) {

}
